import * as React from 'react'
import { ReactComponent as DeskproLogo } from '../../images/logos/deskproLogo.svg'

import image1 from '../../images/projects/deskpro/deskpro-1.png'
import image2 from '../../images/projects/deskpro/deskpro-2.png'
import image3 from '../../images/projects/deskpro/deskpro-3.png'
import image4 from '../../images/projects/deskpro/deskpro-4.png'
import image5 from '../../images/projects/deskpro/deskpro-5.png'


const Deskpro: React.FC = () => {
  return (
    <div className="content">
      <header>
        <div className="projectLogo"><DeskproLogo /></div>
        <h1>Deskpro</h1>
        <h2>Helpdesk software you can depend on.</h2>
        <h3>UX/UI, &amp; Front end development</h3>
        <h4><a href="http://deskpro.com/" target="_blank" rel="noreferrer">deskpro.com</a></h4>
      </header>
      
      <p>I joined DeskPro at a time when the aging product needed an overhaul. Working with the team there, the agent interface was redesigned to simplify a complex interface and allow for extensibility in the future.</p>
      <p>A major part of the redesign was keeping a balance between the information heavy interface, and hiding unnecessary complexity.</p>
      <img src={image1} alt="A screenshot of the deskprop main interface." />
      <img src={image2} alt="A screenshot of advanced settings." />
      <img src={image3} alt="A screenshot of complex permission settings." />
      <img src={image4} alt="A screenshot of a user setting up complex user forms." />
      <img src={image5} alt="A screenshot of the knowledge base mobile interface." />
      
      
      
    </div>
  )
}

export default Deskpro