import * as React from 'react'
import { ReactComponent as MoodLogo } from '../../images/logos/moodLogo.svg'

// @ts-ignore
import video from '../../images/projects/mood/mood_player.webm'
import image1 from '../../images/projects/mood/image1.png'
import image2 from '../../images/projects/mood/image2.png'

const Mood: React.FC = () => {
  return (
    <div className="content">
    <header>
        <div className="projectLogo"><MoodLogo /></div>
        <h1>Mood</h1>
        <h2>Find out what your customers really feel.</h2>
        <h3>UX/UI, React, Testing</h3>
      </header>

      <p>For Mood, I designed &amp; developed a custom audio player, implemented subscription flow designs into the React.js frontend, including user accounts and stripe payment integration. Taking over the vanilla react.js app, I added typescript, unit &amp; end-to-end testing and developed several new features based on existing designs for both desktop and mobile devices.</p>
      
      <div className="videoWrapper">
        <video controls src={video} />
      </div>

      <img src={image1} alt="A screenshot of the basket interface." />
      <img src={image2} alt="A screenshot of subscription workflow." />
    </div>
  )
}

export default Mood