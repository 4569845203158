import * as React from 'react'
import { ReactComponent as MoodLogo } from '../../images/logos/moodLogo.svg'

import image1 from '../../images/projects/skulabs/skulabs_inventory.png'
import image2 from '../../images/projects/skulabs/skulabs_shippingPackaging.png'
import image3 from '../../images/projects/skulabs/skulabs_shippingRateRequest.png'
import image4 from '../../images/projects/skulabs/skulabs_transfers.png'
import image5 from '../../images/projects/skulabs/skulabs_transferActions.png'
import image6 from '../../images/projects/skulabs/skulabs_transferMobile.png'

import image7 from '../../images/projects/skulabs/skulabs_landing_features.png'
import image8 from '../../images/projects/skulabs/skulabs_landing_featuresMobile.png'
import image9 from '../../images/projects/skulabs/skulabs_landing_pricing.png'

const SKULabs: React.FC = () => {
  return (
    <div className="content">
    <header>
        <div className="projectLogo"><MoodLogo /></div>
        <h1>SKULabs</h1>
        <h2>Supercharge your ecommerce business with an all-in-one solution.</h2>
        <h3>UX/UI, Javascript, CSS, Tests</h3>
      </header>

      <p>Designed and helped build a new greenfield inventory system for SKULabs, along with product design work.</p>
      
      <img src={image1} alt="A screenshot of the SKULabs inventory UI." />
      <img src={image2} alt="A screenshot of the SKULabs shipping UI - selecting package type." />
      <img src={image3} alt="A screenshot of the SKULabs shipping IO - selecting shipping rates ." />
      <img src={image4} alt="A screenshot of the SKULabs transfer UI." />
      <img src={image5} alt="A screenshot of the SKULabs transfer UI - bulk actions." />
      <img src={image6} alt="A screenshot of the SKULabs transfer UI on mobile." className="mob" />

      <h4>Product design</h4>
      <img src={image7} alt="A screenshot of the SKULabs landing page." />
      <img src={image8} alt="A screenshot of the SKULabs landing page on mobile." className="mob" />
      <img src={image9} alt="A screenshot of the SKULabs pricing page." />
    </div>
  )
}

export default SKULabs
