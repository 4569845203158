import * as React from 'react'
import { ReactComponent as DeskproLogo } from '../images/logos/deskproLogo.svg'
import { ReactComponent as MonolithLogo } from '../images/logos/monolithLogo.svg'
import { ReactComponent as SkulabsLogo } from '../images/logos/skulabsLogo.svg'
import { ReactComponent as MoodLogo } from '../images/logos/moodLogo.svg'
import { ReactComponent as MakersiteLogo } from '../images/logos/makersiteLogo.svg'
import { ReactComponent as HungryhouseLogo } from '../images/logos/hungryhouseLogo.svg'
import { ReactComponent as InmotionLogo } from '../images/logos/inmotionLogo.svg'
import { ReactComponent as LetterboxLogo } from '../images/logos/letterboxLogo.svg'
import PortfolioIntro from './PortfolioIntro'
import { Link, useNavigate } from 'react-router-dom'

const PortfolioLanding: React.FC = () => {
  const navigate = useNavigate()
  
  React.useEffect(() => { updateExpanded(true) }, [])

  const expandArticle = (article: string) => {
    updateExpanded(false)
    setTimeout(() => navigate(`/portfolio?project=${article}`), 500)
  }

  const handleKeyPress = (e: React.KeyboardEvent, articleId: string) => {
    if (e.code === "Enter") {
      expandArticle(articleId)
    }
  }

  const [expanded, updateExpanded] = React.useState(false)

  return (
    <section className={`designForDetails ${expanded ? 'expanded' : ''}`}>
      <a className="jumpToContent" href="#content" tabIndex={0}>Jump to content</a>
      <header>
        <h1>Dennis Schipper, UX/UI Engineer.</h1>
        <h2>I design and develop web apps.</h2>
        <h2>The kind with loads of data.</h2>
      </header>

      <article className="intro">
        <p>Besides this portfolio, there's more work on <a href="https://dribbble.com/dennis-schipper" rel="noreferrer" tabIndex={0}>dribbble</a> &amp; open source code on <a href="https://github.com/dennisschipper" rel="noreferrer">github</a>.</p>
      </article>

      <section className="introProjects" id="content">
        <ul>
          <li>
            <PortfolioIntro handleKeyPress={handleKeyPress} expandArticle={expandArticle} id="skulabs" logo={<SkulabsLogo />} title="SKULabs" subtitle="Supercharge your ecommerce business with an all-in-one solution." skills="UX/UI, Javascript, CSS, Tests"/>
          </li>
          <li>
            <PortfolioIntro handleKeyPress={handleKeyPress} expandArticle={expandArticle} id="monolith" logo={<MonolithLogo />} title="Monolith" subtitle="Accelerate product validation with AI-guided tes." skills="UX/UI, Javascript, CSS, Tests, Python"/>
          </li>
          <li>
            <PortfolioIntro handleKeyPress={handleKeyPress} expandArticle={expandArticle} id="mood" logo={<MoodLogo />} title="Mood" subtitle="Find out what your customers really feel." skills="UX, UI, Javascript"/>
          </li>
          <li>
            <PortfolioIntro handleKeyPress={handleKeyPress} expandArticle={expandArticle} id="deskpro" logo={<DeskproLogo />} title="Deskpro" subtitle="Helpdesk software you can depend on." skills="UX, UI, HTML/CSS"/>
          </li>
          <li>
            <PortfolioIntro handleKeyPress={handleKeyPress} expandArticle={expandArticle} id="makersite" logo={<MakersiteLogo />} title="Makersite" subtitle="Accelerate your product development process to make better products, faster." skills="UX, UI, HTML/CSS"/>
          </li>
          <li>
            <PortfolioIntro handleKeyPress={handleKeyPress} expandArticle={expandArticle} id="hungryhouse" logo={<HungryhouseLogo />} title="HungryHouse" subtitle="Restaurant home delivery." skills="Mobile app design"/>
          </li>
          <li>
            <PortfolioIntro handleKeyPress={handleKeyPress} expandArticle={expandArticle} id="inmotion" logo={<InmotionLogo />} title="inMotion" subtitle="VC &amp; startup accelerator by JLR." skills="MVPs, A/B testing, user aquisition"/>
          </li>
          <li>
            <PortfolioIntro handleKeyPress={handleKeyPress} expandArticle={expandArticle} id="letterbox" logo={<LetterboxLogo />} title="Letterbox" subtitle="Inbound newsletter management" skills="UI/UX, React, Ruby on Rails"/>
          </li>
        </ul>
      </section>
    </section>
  )
}

export default PortfolioLanding
