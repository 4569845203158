import * as React from 'react'

interface IProps {
  expandArticle: (articleId: string) => void
  handleKeyPress: (e: React.KeyboardEvent, articleId: string) => void
  title: string
  subtitle: string
  skills: string
  logo: React.ReactElement
  id: string
}

const PortfolioIntro: React.FC<IProps> = (props) => {
  return (
    <div className="projectIntroWrapper" tabIndex={0} onKeyPress={(e: React.KeyboardEvent) => {props.handleKeyPress(e, props.id)} } onClick={() => props.expandArticle(props.id)}>
      <article className="projectIntro">
        <div className="projectLogo">
          {props.logo}
        </div>
        <h1>{props.title}</h1>
        <h2>{props.subtitle}</h2>
        <h3>{props.skills}</h3>
      </article>
    </div>
  )
}

export default PortfolioIntro