import * as React from 'react'
import { ReactComponent as LetterboxLogo } from '../../images/logos/letterboxLogo.svg'

import image1 from '../../images/projects/letterbox/letterbox-1.png'
import image2 from '../../images/projects/letterbox/letterbox-2.png'

const Letterbox: React.FC = () => {
  return (
    <div className="content">
      <header>
        <div className="projectLogo"><LetterboxLogo /></div>
        <h1>Letterbox</h1>
        <h2>A newsfeed for all your newsletters.</h2>
        <h3>UX/UI, React, Ruby on Rails</h3>
      </header>
      <p>Subscribe to newsletters with your own private email, then view them in a newsfeed-like interface. Personal project.</p>
      <img src={image1} alt="A screenshot of the letterbox main interface." />
      <img src={image2} alt="A screenshot of the letterbox landing page." />
    </div>
  )
}

export default Letterbox