import * as React from 'react'
import { ReactComponent as InmotionLogo } from '../../images/logos/inmotionLogo.svg'

import image1 from '../../images/projects/inmotion/inmotion-1.png'
import image2 from '../../images/projects/inmotion/inmotion-2.png'
import image3 from '../../images/projects/inmotion/inmotion-3.png'

const InMotion: React.FC = () => {
  return (
    <div className="content">
      <header>
        <div className="projectLogo"><InmotionLogo /></div>
        <h1>InMotion</h1>
        <h2>Transport &amp; Mobility startup accelerator by Jaguar - Land Rover.</h2>
        <h3>UX/UI, MVPs, A/B testing</h3>
        <h4><a href="https://www.inmotionventures.com/" target="_blank" rel="noreferrer">inmotionventures.com</a></h4>
      </header>
      <p>Assisted cohorts on the accelerator program to launch MPVs and test hypotheses.</p>
      <img src={image1} alt="A screenshot of a series of apps for a mobility platform." />
      <img src={image2} alt="A screenshot of a mobile app showing in-car wifi usage." />
      <img src={image3} alt="A screenshot of a landing page for a mobility platform." />
    </div>
  )
}

export default InMotion