import * as React from 'react'

interface IProps {
  onClick: (e: React.BaseSyntheticEvent) => void
}

const BackButton: React.FC<IProps> = (props) => {
  return (
    <nav className="backButton">
      <a href="/" onClick={props.onClick}>Back</a>
    </nav>
  )
}

export default BackButton