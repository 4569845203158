import * as React from 'react'
import DesignForDetails from './DesignForDetails'
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import PortfolioItem from './components/PortfolioItem';
import Contact from './components/Contact';

const AppRouter: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<DesignForDetails />} />
        <Route path="/portfolio" element={<PortfolioItem />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      
    </Router>
  )
}

export default AppRouter
