import * as React from 'react'
import { ReactComponent as HungryhouseLogo } from '../../images/logos/hungryhouseLogo.svg'

import image1 from '../../images/projects/hungryhouse/hungryhouse-1.png'
import image2 from '../../images/projects/hungryhouse/hungryhouse-2.png'

const HungryHouse: React.FC = () => {
  return (
    <div className="content">
      <header>
        <div className="projectLogo"><HungryhouseLogo /></div>
        <h1>HungryHouse (Aquired by Just Eat)</h1>
        <h2>Takeaway &amp; restaurantfood delivery.</h2>
        <h3>Mobile app Ui & Ux, Prototyping</h3>
      </header>
      <p>Redesign of the HungryHouse mobile &amp; tablet app.</p>
      <img src={image1} alt="A screenshot of the hungryhouse mobile app for restaurant deliveries." />
      <img src={image2} alt="A screenshot of the hungryhouse mobile app for a restaurant menu." />
    </div>
  )
}

export default HungryHouse