import * as React from 'react'

const Contact: React.FC = () => {
  return (
    <section className="page contact">
      <header>
        <h1>Have a project in mind?</h1>
      </header>
      <p>Always happy to have a talk about new projects. Currently, I am only looking for small, well funded startups looking for a generalist to handle the frontend/design/ux side.</p>
      <p>Email at dennis[at]designfordetails[dot]com</p>
    </section>
  )
}

export default Contact