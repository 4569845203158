import * as React from 'react'
import { useNavigate } from "react-router-dom";
import BackButton from './BackButton';
import Deskpro from './PortfolioItems/Deskpro';
import HungryHouse from './PortfolioItems/HungryHouse';
import InMotion from './PortfolioItems/InMotion';
import Letterbox from './PortfolioItems/Letterbox';
import Makersite from './PortfolioItems/Makersite';
import Monolith from './PortfolioItems/Monolith';
import Mood from './PortfolioItems/Mood';
import SKULabs from './PortfolioItems/SKULabs';

const PortfolioItem: React.FC = () => {
  const navigate = useNavigate()
  const [leaving, updateLeaving] = React.useState<boolean>(false)
  
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const returnToLanding = () => {
    updateLeaving(true)
    setTimeout(() => navigate("/"), 500)
  }

  const handleBackClick = (e: React.BaseSyntheticEvent) => {
    e.preventDefault()
    returnToLanding()
  }

  const urlProjectParam = new URLSearchParams(window.location.search).get('project')
  
  const portfolioContent = () => {
    switch(urlProjectParam) {
      case 'skulabs': return <SKULabs />
      case 'mood': return <Mood />
      case 'monolith': return <Monolith />
      case 'deskpro': return <Deskpro />
      case 'makersite': return <Makersite />
      case 'inmotion': return <InMotion />
      case 'letterbox': return <Letterbox />
      case 'hungryhouse': return <HungryHouse />
      default: <Monolith />
    }
  }

  return (
    <article className={`portfolioItem ${leaving ? 'leaving' : ''}`}>
      <BackButton onClick={handleBackClick} />
      {portfolioContent()}
    </article>
  )
}

export default PortfolioItem
