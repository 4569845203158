import * as React from 'react'
import { ReactComponent as MonolithLogo } from '../../images/logos/monolithLogo.svg'

import image1 from '../../images/projects/monolith/monolith-1.png'
import image3 from '../../images/projects/monolith/monolith-3.png'
import image4 from '../../images/projects/monolith/monolith-4.png'
import image5 from '../../images/projects/monolith/monolith-5.png'

const Monolith: React.FC = () => {
  return (
    <div className="content">
      <header>
        <div className="projectLogo"><MonolithLogo /></div>
        <h1>MonolithAI</h1>
        <h2>The No-code AI platform for product engineering</h2>
        <h3>UX/UI, React, Python, Testing (jest/cypress)</h3>
        <h4><a href="https://www.monolithai.com/" target="_blank" rel="noreferrer">monolithai.com</a></h4>
      </header>
      
      <p>I joined Monolith after their first round of funding, when the software was an MVP.</p>
      <p>I was the primary front end developer &amp; designer during my time there, helping to expand and update the react/redux frontend app, write unit &amp; and-to-end tests and design and launch several new features to be used by companies like Honda, Tata Motors and Siemens.</p>
      
      <img src={image1} alt="A screenshot of the interactive dashboard." />      
      <img src={image3} alt="A screenshot of the Monolith notebook interface." />
      <img src={image4} alt="A screenshot of the collaboration interface for notebooks." />
      <img src={image5} alt="A screenshot of the grid search interface." />
      
    </div>
  )
}

export default Monolith