import * as React from 'react'
import { ReactComponent as MakersiteLogo } from '../../images/logos/makersiteLogo.svg'

import image1 from '../../images/projects/makersite/makersite-1.png'
import image2 from '../../images/projects/makersite/makersite-2.png'
import image3 from '../../images/projects/makersite/makersite-3.png'
import image4 from '../../images/projects/makersite/makersite-4.png'
import image5 from '../../images/projects/makersite/makersite-5.png'
import image6 from '../../images/projects/makersite/makersite-6.png'
import image7 from '../../images/projects/makersite/makersite-7.png'

const Makersite: React.FC = () => {
  return (
    <div className="content">
      <header>
        <div className="projectLogo"><MakersiteLogo /></div>
        <h1>Makersite</h1>
        <h2>Accelerate your product development process</h2>
        <h3>UX/UI, Front end</h3>
        <h4><a href="https://makersite.io/" target="_blank" rel="noreferrer">makersite.io</a></h4>
      </header>
      
      <p>When Makersite first reached out, the platform was at an mvp stage, with a base product built on top of a template. Over two years, the platform was redesigned &amp; new internal and public features launched.</p>
      <img src={image1} alt="A screenshot of the interactive dashboard." />
      <img src={image2} alt="A screenshot of the interactive dashboard." />
      <p>We tested new features with users, recorded their behaviour to confirm assumptions, and built interactive prototypes of new features before implementation.</p>
      <img src={image3} alt="A screenshot of the interactive dashboard." />
      <p>Features where launched to help users explore the massive amounts of data Makersite can provide to manufacturers - from costs, to environmental effects and manufacturing locations.</p>
      <img src={image4} alt="A screenshot of the interactive dashboard." />
      <p>Much of the work was data heavy or dependant of data visualization across different metrics.</p>
      <img src={image5} alt="A screenshot of the interactive dashboard." />
      <p>I worked directly with the founders, developers and an outsourced team to gather requirements, design new platform products &amp; code prototypes. With the team, we added new features for users to discover and improve costs, import & visualize data and dozens more.</p>
      <hr />
      <p>For the American Chemistry Council, we built a new web app to promote the Multi Criteria Data Analysis methodology. Users browsed through a set of custom data, and created custom weighted reports based on customer preferences.</p>
      <img src={image6} alt="A screenshot of the interactive dashboard." />
      <img src={image7} alt="A screenshot of the interactive dashboard." />
      <p>The work done laid the foundation for Makersite to secure new contracts, set a baseline for the platform's design for the future & provide valuable solutions to Makersite's customers.</p>
    </div>
  )
}

export default Makersite